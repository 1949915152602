import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import moment from "moment";
import { t } from "i18next";
import { useUpdatePreferencesMutation } from "../../../redux/api/contactsApi";
import { DatePicker, Select, Text } from "../Inputs";
import Button from "./Button";
import { useLocation, useParams } from "react-router-dom";

const ModalForPreferences = ({ modalOptions, setModalOptions, setShow, setMessage, data, setBackgroundToast }) => {
  const closeModal = () => {
    setModalOptions(() => ({
      isOpen: false,
    }));
  };
  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;
  // Extract default values for form fields from `data`
  const defaultValues = React.useMemo(() => {
    const initialValues = {};
    const leadChannelsData = data?.data?.lead_channels;
    const allChannels = data?.allChannels;

    // Dynamically setting up frequency, pause dates based on channel types from allChannels
    Object.entries(allChannels).forEach(([channelName, channelKey]) => {
      initialValues[`frequency_number_${channelKey}`] = leadChannelsData?.[`frequency_number_${channelKey}`];
      initialValues[`frequency_time_${channelKey}`] = leadChannelsData?.[`frequency_time_${channelKey}`];
      initialValues[`contact_pause_start_date_${channelKey}`] = leadChannelsData?.[
        `contact_pause_start_date_${channelKey}`
      ]
        ? moment(leadChannelsData[`contact_pause_start_date_${channelKey}`]).format("YYYY-MM-DD")
        : "";
      initialValues[`contact_pause_end_date_${channelKey}`] = leadChannelsData?.[`contact_pause_end_date_${channelKey}`]
        ? moment(leadChannelsData[`contact_pause_end_date_${channelKey}`]).format("YYYY-MM-DD")
        : "";

      // For subscribed channels, check if the channel key exists in leadChannels to mark as subscribed
      initialValues[`subscribed_channels_${channelKey}`] = Object.keys(data?.leadChannels).includes(channelKey);
    });

    // Setting the preferred channel correctly
    // Assuming preferred_channel's value is directly the channel key (e.g., "email" or "sms")
    const preferredChannelKey = leadChannelsData?.preferred_channel; // This should directly give us the key, e.g., "email"
    if (preferredChannelKey) {
      // We ensure the preferred channel is correctly set by looking for its existence in allChannels
      const isPreferredChannelValid = Object.values(allChannels).includes(preferredChannelKey);
      if (isPreferredChannelValid) {
        // If the preferred channel is valid, set it
        initialValues[`preferred_channel`] = preferredChannelKey;
      }
    }

    return initialValues;
  }, [data]);

  const { control, handleSubmit, watch, reset, setValue } = useForm({
    defaultValues,
  });

  useEffect(() => {
    // Update form values when `data` changes
    Object.keys(defaultValues).forEach((field) => {
      setValue(field, defaultValues[field]);
    });
  }, [data, setValue, defaultValues]);

  const [updateChannels] = useUpdatePreferencesMutation();

  const handleChannels = async (formData) => {
    // Initialize the object structure with dynamic keys
    const leadChannels = Object.entries(formData).reduce((acc, [key, value]) => {
      // Skip fields that are not set; adjust conditions as needed based on your form specifics
      if (value === "" || value === null || value === undefined) {
        return acc;
      }

      // For date fields, convert moment object to string if it's not empty
      if (key.includes("contact_pause_start_date_") || key.includes("contact_pause_end_date_")) {
        acc[key] = value;
      } else {
        acc[key] = value;
      }

      return acc;
    }, {});

    const dataToSend = {
      frequencyRules: {
        lead_channels: leadChannels,
      },
    };

    try {
      const response = await updateChannels({ id: id, ...dataToSend });
      if (!response.error) {
        closeModal();
        setShow(true);
        setMessage(t("caymland.message.type.successfully", { type: "edited" }));
        setBackgroundToast("1");
        setTimeout(() => setShow(false), 3000);
      } else {
        setShow(true);
        setMessage(t("caymland.message.type.error"));
        setBackgroundToast("2");
        setTimeout(() => {
          setShow(false);
          setMessage("");
          setBackgroundToast("1");
        }, 3000);
      }
    } catch (error) {
    }
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: closeModal,
    },
    {
      name: "save",
      title: t("caymland.core.form.save"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSubmit(handleChannels),
    },
  ];

  const options = [
    { label: t("caymland.core.time.days"), value: "DAY" },
    { label: t("caymland.core.time.weeks"), value: "WEEK" },
    { label: t("caymland.core.time.months"), value: "MONTH" },
  ];

  return (
    <Modal show={modalOptions.isOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            padding: "4px",
            fontSize: "18px",
          }}
        >
          {t("caymland.lead.lead.header.contact.frequency")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div // Header row for channel configuration
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 2fr 6fr 2fr",
            backgroundColor: "#F3F3F3",
            color: "black",
            padding: "0.7em 0 0.7em 0",
          }}
        >
          <Controller
            control={control}
            name={`subscribed_channels`}
            render={({ field }) => (
              <input
                type="checkbox"
                {...field}
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                style={{ width: "14px", height: "14px", marginLeft: "17px", marginTop: "6px" }}
              />
            )}
          />
          <span>{t(`caymland.core.channels`)}</span>
          <span>{t("caymland.lead.preferred.frequency")}</span>
          <span style={{ justifySelf: "center" }}>{t("caymland.lead.preferred.channels")}</span>
        </div>

        {data &&
          Object.values(data.allChannels).map((channelKey, index) => {
            const channelData = data[channelKey]; // Extract data for the current channel
            return (
              <React.Fragment key={channelKey}>
                {index > 0 && (
                  <div // Divider for visual separation between channel configurations
                    style={{
                      borderTop: "1px solid #E9ECEF",
                      margin: "20px 0",
                    }}
                  />
                )}

                <div // Main configuration row for channel
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr 6fr 2fr",
                    color: "#8393a2",
                    padding: "0.7em 0 0.7em 0",
                    marginTop: "10px",
                    alignItems: "center",
                  }}
                >
                  <Controller
                    control={control}
                    name={`subscribed_channels_${channelKey}`}
                    render={({ field }) => (
                      <input
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        style={{ width: "14px", height: "14px", marginLeft: "17px" }}
                      />
                    )}
                  />
                  <span style={{ color: watch(`subscribed_channels_${channelKey}`) ? "black" : "#8393a2" }}>
                    {t(`caymland.${channelKey}.${channelKey}`)}
                  </span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 3fr",
                      }}
                    >
                      <Controller
                        control={control}
                        name={`frequency_number_${channelKey}`}
                        render={({ field }) => <Text {...field} type="number" label="" />}
                      />
                      <span style={{ alignSelf: "center", justifySelf: "center" }}>
                        {t("caymland.lead.list.frequency.times")}
                      </span>
                      <Controller
                        control={control}
                        name={`frequency_time_${channelKey}`}
                        render={({ field: { name, onChange, value } }) => (
                          <Select
                            name={name}
                            value={value}
                            onChange={(selectedOption) => onChange(selectedOption.value)}
                            options={options}
                            label=""
                          />
                        )}
                      />
                    </div>
                  </div>
                  <Controller
                    control={control}
                    name="preferred_channel"
                    render={({ field }) => (
                      <input
                        type="radio"
                        {...field}
                        checked={field.value === channelKey}
                        onChange={() => field.onChange(channelKey)}
                      />
                    )}
                  />
                </div>
                {(watch(`subscribed_channels_${channelKey}`) || watch("subscribed_channels")) && (
                  <div // Pause dates row for channel
                    style={{
                      display: "flex",
                      gridTemplateColumns: "1fr 2fr 6fr 2fr",
                      padding: "10px 20px",
                      marginTop: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <span className="input-group-text" style={{ height: "40px" }}>
                        {t("caymland.lead.frequency.dates.label")}
                      </span>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Controller
                          control={control}
                          name={`contact_pause_start_date_${channelKey}`}
                          render={({ field: { name, onChange, value } }) => (
                            <DatePicker
                              name={name}
                              className={`form-control no-border-radius`}
                              showTimeSelect={false}
                              dateFormat="dd-MM-yyyy"
                              value={value}
                              onChange={(date) => onChange(date ? moment(date).format("YYYY-MM-DD") : null)}
                              isClearable
                            />
                          )}
                        />
                        <span className="input-group-text" style={{ height: "40px" }}>
                          {t("caymland.core.date.to")}
                        </span>
                        <Controller
                          control={control}
                          name={`contact_pause_end_date_${channelKey}`}
                          render={({ field: { name, onChange, value } }) => (
                            <DatePicker
                              name={name}
                              className={`form-control no-border-radius`}
                              showTimeSelect={false}
                              dateFormat="dd-MM-yyyy"
                              value={value}
                              onChange={(date) => onChange(date ? moment(date).format("YYYY-MM-DD") : null)}
                              isClearable
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          })}
      </Modal.Body>
      <Modal.Footer>
        <Button buttons={buttons} />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalForPreferences;
