import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Statistics from "./TabsComponents/Statistics";
import History from "./TabsComponents/History";
import AuditLog from "./TabsComponents/AuditLog";
import Notes from "./TabsComponents/Notes";
import SeeTicket from "./TabsComponents/SeeTicket";
import Files from "./TabsComponents/Files";

const Tabs = ({ tabs, statistics, id }) => {
  // Function to check if statistics object has data
  const hasStatisticsData = () => {
    return statistics && Object.keys(statistics).length > 0;
  };
  useEffect(() => {}, [statistics]);
  // Set initial active tab to 'statistics' if statistics data is available, otherwise 'history'
  const [activeTab, setActiveTab] = useState(statistics ? "statistics" : "history");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  useEffect(() => {
    if (hasStatisticsData()) {
      setActiveTab("statistics");
    } else {
      setActiveTab("history");
    }
  }, [statistics]);

  return (
    <div>
      <Nav tabs>
        {/* Render Statistics Tab first if data is available */}
        {hasStatisticsData() && (
          <NavItem>
            <NavLink className={activeTab === "statistics" ? "active-bg" : ""} onClick={() => toggle("statistics")}>
              Statistics
            </NavLink>
          </NavItem>
        )}

        {/* Render other tabs */}
        {tabs.map((tab, index) => (
          <NavItem key={index}>
            <NavLink className={activeTab === tab.alias ? "active-bg" : ""} onClick={() => toggle(tab.alias)}>
              {tab.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <TabContent activeTab={activeTab}>
        {/* Render Statistics Content */}
        {hasStatisticsData() && (
          <TabPane tabId="statistics">{activeTab === "statistics" && <Statistics statistics={statistics} />}</TabPane>
        )}
        {/* Render History Content */}
        <TabPane tabId="history">{activeTab === "history" && <History id={id} />}</TabPane>

        {/* Render AuditLog Content */}
        <TabPane tabId="audit_log">{activeTab === "audit_log" && <AuditLog id={id} />}</TabPane>
        {/* Render Notes Content */}
        <TabPane tabId="notes">{activeTab === "notes" && <Notes id={id} />}</TabPane>
        {/* Render See Tikcets*/}
        <TabPane tabId="showare">{activeTab === "showare" && <SeeTicket id={id} />}</TabPane>
        {/* Render Files*/}
        <TabPane tabId="files">
          {activeTab === "files" && <Files id={id} tabData={tabs.filter((tab) => tab.alias === "files")} />}
        </TabPane>

        {/* Render other tab content */}
        {/* {tabs.map((tab, index) => (
          <TabPane tabId={tab.alias} key={index}>
            {activeTab === tab.alias ? <div>{`Content for ${tab.name}`}</div> : null}
          </TabPane>
        ))} */}
      </TabContent>
    </div>
  );
};

export default Tabs;
