import React, { useEffect, useState } from "react";
import { FaSignal } from "react-icons/fa";
import ModalForPreferences from "./ModalForPreferences";
import Button from "./Button";
import { Tooltip } from "@mui/material";
import { t } from "i18next";

export default function Preferences(props) {
  const [show, setShow] = useState(false);
  const [publishedSegments, setPublishedSegments] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [backgorundToast, setBackgroundToast] = useState("");

  const [modalOptions, setModalOptions] = useState({
    id: props.id,
    isOpen: false,
    title: "Contact Preference Center",
    type: "channels",
  });
  useEffect(() => {
    setPublishedSegments(props.allSegments?.filter((s) => s.isPublished));
  }, [props.allSegments]);
  return (
    <>
      <ModalForPreferences
        handleClose={handleClose}
        handleShow={handleShow}
        show={show}
        data={props?.data}
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        segments={props.segments}
        allSegments={publishedSegments}
        contactCategories={props.contactCategories}
        allCategories={props.allCategories}
        email={props.email}
        retrieveSegmentsForID={props.retrieveSegmentsForID}
        retrieveContactCategories={props.retrieveContactCategories}
        contactDetails={props.contactDetails}
        setShow={props.setShowAlert}
        setMessage={props.setMessage}
        setBackgroundToast={setBackgroundToast}
      />

      <Button
       color="customGrey"
        buttons={[
          {
            name: !props.noText && "Preferences",
            title: !props.noText && t("caymland.core.preferences"),
            icon: (
              <Tooltip title={t("caymland.core.preferences")} arrow>
                {" "}
                <FaSignal size={15} />
              </Tooltip>
            ),
            onClick: () =>
              setModalOptions((prev) => ({
                ...prev,
                isOpen: true,
              })),
          },
        ]}
      />
    </>
  );
}
