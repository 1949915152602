import React, { useEffect, useMemo, useState } from "react";
import FieldsMailbox from "./FieldsMailbox";
import { useSelector } from "react-redux";
import { countryData } from "../../reusableComponents/data";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../../components/ui/tooltip";
import { Button } from "../../ui/button";
import { ArrowBigLeft, User, CirclePlus, RefreshCcw, Save } from "lucide-react";
import { Separator } from "../../ui/separator";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../components/ui/table";
import { ResizablePanel } from "../../../components/ui/resizable";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { t } from "i18next";
import { contactsApi, useAddContactMutation, useUpdateContactsMutation } from "../../../../src/redux/api/contactsApi";
import {
  useEditMessageMutation,
  useGetMailboxFieldsQuery,
  useMessageReadMutation,
} from "../../../../src/redux/api/mailboxApi";
import { SlideAlert } from "../../reusableComponents/Alerts";

const FieldSection = ({ parsedEmail, activeMail, selectedText, setSelectedText, mailList, refetch }) => {
  const [editableField, setEditableField] = useState(null);
  const [fieldValues, setFieldValues] = useState({});
  const [initialFieldValues, setInitialFieldValues] = useState({});
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [backgorundToast, setBackgroundToast] = useState("");
  const [dnc, setDnc] = useState(false);

  const countries = useSelector((state) => state?.settings?.data?.countries || countryData);
  const localeData = useSelector((state) => state?.settings?.data?.locales || localeData);

  const [addDoNotContact] = contactsApi.useAddDoNotContactMutation();
  const [removeDoNotContact] = contactsApi.useRemoveDoNotContactMutation();
  const { data: orderFields } = useGetMailboxFieldsQuery();
  const fieldsArray = useMemo(() => Object.values(orderFields || {}), [orderFields]);
  const [addContact] = useAddContactMutation();
  const [updateContact] = useUpdateContactsMutation();
  const [editMessage] = useEditMessageMutation();
  const [messageRead] = useMessageReadMutation();

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, dirtyFields, touchedFields },
  } = useForm({
    resolver: yupResolver(yup.object({})),
  });

  const handleUserIconClick = async () => {
    if (activeMail.lead) {
      window.open(`/contacts/view/${activeMail.lead.id}`, "_blank");
    } else if (!activeMail.lead) {
      const updatedMail = await mailList?.data?.find((m) => m.id === activeMail.id);
      window.open(`/contacts/view/${updatedMail?.lead?.id}`, "_blank");
    }
    return;
  };

  useEffect(() => {
    if (activeMail?.lead?.doNotContact?.length > 0) {
      setDnc(activeMail?.lead?.doNotContact?.length > 0);
    } else {
      setDnc(false);
    }
  }, [activeMail]);

  useEffect(() => {
    if (activeMail?.lead) {
      const lead = activeMail.lead;
      const leadData = {
        ...lead,
        date: lead.date ? moment(lead.date).format("YYYY-MM-DD") : "",
      };

      setInitialFieldValues(leadData);
      setValue("email", "");
      reset(leadData);
    }
  }, [activeMail, reset]);

  useEffect(() => {
    reset({});
    setFieldValues({});
    setEditableField(null);

    if (parsedEmail && !activeMail.lead) {
      const identityHeader = parsedEmail.headers.find((header) => header.key === "x-caymland-foundidentity")?.value;
      if (identityHeader) {
        const identityData = identityHeader.split("|").reduce((acc, curr) => {
          const [key, value] = curr.split("=");
          acc[key.trim()] = decodeURIComponent(value.trim());
          return acc;
        }, {});
        setFieldValues(identityData);
        setInitialFieldValues(identityData);
      }
    }
  }, [parsedEmail]);

  const getFieldValue = (fieldAlias) => fieldValues[fieldAlias] || "";

  const formatFieldValue = (field, value) => {
    if (value === undefined || value === null) {
      return "";
    }

    switch (field?.type) {
      case "boolean":
        return value[0]?.reason === 1 ||
          value === true ||
          value[0]?.reason === 2 ||
          value[0]?.reason === 3 ||
          value[0]?.reason === 0
          ? t("caymland.core.form.yes")
          : value.length === 0 || value === false
          ? t("caymland.core.no")
          : "";
      case "multiselect":
        if (typeof value === "string") {
          return value
            .split("|")
            .map((val) => {
              const option = field?.properties?.list?.find((item) => item?.value === val);
              return option ? option?.label : val;
            })
            .join(", ");
        } else {
          return "";
        }
      case "country":
        return countries.find((country) => country?.value === value)?.label;
      case "locale":
        return localeData.find((locale) => locale?.value === value)?.label;
      case "datetime":
        return moment(value).format("DD.MM.YYYY HH:mm") || value;
      case "date":
        return moment(value).format("DD.MM.YYYY ") || value;
      default:
        return value?.length > 30 ? value?.slice(0, 30) + "..." : value;
    }
  };

  const toggleEditable = (fieldId) => {
    if (editableField === fieldId) {
      setEditableField(null);
    } else {
      setEditableField(fieldId);
    }
  };

  const setField = (fieldAlias) => {
    const value = getFieldValue(fieldAlias);
    if (value) {
      setValue(fieldAlias, value, { shouldValidate: true });
      setFieldValues((prevValues) => {
        const updatedValues = { ...prevValues };
        delete updatedValues[fieldAlias];
        return updatedValues;
      });
    }
  };

  const handleRefresh = () => {
    setFieldValues(initialFieldValues);
    reset();
  };

  const handleAddNew = () => {
    reset({});

    setValue("email", "");
    setFieldValues({});
    setEditableField(null);
  };

  const handleBack = () => {
    Object.keys(initialFieldValues).forEach((fieldAlias) => {
      setValue(fieldAlias, initialFieldValues[fieldAlias], { shouldValidate: true });
    });
    setFieldValues({});
  };

  const handleSaveContact = async (formData) => {
    if (!activeMail) {
      return;
    }

    formData.dnc = "";
    let response;

    if (activeMail.lead && activeMail.lead.id) {
      response = await updateContact({ id: activeMail.lead.id, ...formData });
    } else {
      response = await addContact(formData);
    }

    if (response?.data?.contact) {
      const editPayload = {
        lead: response?.data?.contact?.id,
      };

      await editMessage({ id: activeMail?.id, ...editPayload });

      await refetch();

      if (response?.data?.contact) {
        setShow(true);
        setMessage(
          t("caymland.category.notice.updated", {
            name: `${response?.data?.contact?.fields?.all?.firstname} ${response?.data?.contact?.fields?.all?.lastname}`,
          })
        );
        setBackgroundToast("2");
        setTimeout(() => {
          setShow(false);
          setMessage("");
          setBackgroundToast("1");
        }, 50000);
      }
    } else {
      console.error("Failed to create/update contact.");
    }
  };

  const handleSetSelectedText = (fieldAlias) => {
    if (selectedText) {
      setValue(fieldAlias, selectedText, { shouldValidate: true });
      setSelectedText("");
    }
  };

  const addRemoveDNC = async () => {
    const id = await activeMail?.lead?.id;
    let dncState =
      (await activeMail?.lead?.doNotContact?.length) > 0
        ? true
        : activeMail?.lead?.doNotContact?.length === 0
        ? false
        : null;

    try {
      let response;

      // if (dncState === true || dncState === false) {
      if (!dnc) {
        response = await addDoNotContact({ id }).unwrap();
        setDnc(true);
      }
      if (dnc) {
        response = await removeDoNotContact({ id }).unwrap();
        setDnc(false);
      }
      // }
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <ResizablePanel defaultSize={35} minSize={25}>
      <SlideAlert background={backgorundToast} show={show} message={message} setShowAlert={setShow} />
      <div className="flex h-full flex-col">
        <div className="flex items-center p-2 tw-justify-end">
          <div className="flex items-center  gap-2">
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="ghost" size="icon" onClick={handleUserIconClick}>
                  <User className="tw-h-5 tw-w-5" />
                  <span className="sr-only">{t("caymland.queue.config.rabbitmq.user")}</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>{t("caymland.queue.config.rabbitmq.user")}</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="ghost" size="icon">
                  <CirclePlus className="tw-h-5 tw-w-5" onClick={handleAddNew} />
                  <span className="sr-only">{t("caymland.core.form.add")}</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>{t("caymland.core.form.add")}</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="ghost" size="icon" onClick={handleRefresh}>
                  <RefreshCcw className="tw-h-5 tw-w-5" />
                  <span className="sr-only">{t("caymland.ebas.form.gettoken")}</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>{t("caymland.ebas.form.gettoken")}</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="ghost" size="icon" onClick={handleBack}>
                  <ArrowBigLeft className="tw-h-5 tw-w-5" />
                  <span className="sr-only">{t("caymland.modal.back")}</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>{t("caymland.modal.back")}</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="ghost" size="icon" onClick={handleSubmit((data) => handleSaveContact(data))}>
                  <Save className="tw-h-5 tw-w-5" />
                  <span className="sr-only">{t("caymland.module.account.button")}</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>{t("caymland.module.account.button")}</TooltipContent>
            </Tooltip>
          </div>
        </div>
        <Separator />
        <div className="tw-p-3  tw-h-full ">
          <Table>
            <TableHeader className={"tw-bg-secondary"}>
              <TableRow>
                {selectedText.length > 0 && (
                  <TableHead className={"tw-w-[5%] "}>
                    {" "}
                    <ArrowForwardIcon
                      sx={{
                        color: "hsl(215.4, 16.3%, 46.9%)",
                        fontSize: "16px",
                      }}
                    />
                  </TableHead>
                )}

                <TableHead className="tw-text-[12px] tw-flex-1">{t("caymland.mailbox.contact.field.field")}</TableHead>
                <TableHead className="tw-text-[12px] tw-w-[30%]">
                  {t("caymland.mailbox.contact.field.system")}
                </TableHead>
                <TableHead className={"tw-text-[12px] tw-w-[5%]"}>
                  {" "}
                  <ArrowBackIcon
                    sx={{
                      color: "hsl(215.4, 16.3%, 46.9%)",
                      fontSize: "14px",
                    }}
                  />
                </TableHead>
                <TableHead className="text-right tw-text-[12px] tw-w-[30%]">
                  {t("caymland.mailbox.contact.field.proposal")}
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {fieldsArray?.map((field, index) => {
                const fieldValue = getFieldValue(field.alias);
                if (field?.alias === "dnc" && (activeMail === null || activeMail?.lead === null)) {
                  return null;
                }

                return (
                  <TableRow key={index}>
                    {selectedText.length > 0 && (
                      <TableCell className="font-medium tw-text-center  hover:tw-scale-120 tw-duration-150 tw-cursor-pointer tw-w-[5%]">
                        {" "}
                        <ArrowForwardIcon
                          classes={"hover:tw-scale-120 tw-duration-150"}
                          sx={{
                            color: "hsl(215.4, 16.3%, 46.9%)",
                            fontSize: "16px",
                          }}
                          onClick={() => handleSetSelectedText(field?.alias)}
                        />
                      </TableCell>
                    )}
                    <TableCell className="hover:tw-scale-105 tw-duration-150 tw-cursor-pointer tw-pl-2 tw-flex-1 tw-text-[12px]">
                      {field.label.length > 25 ? field.label.slice(0, 25) + "..." : field.label}
                      <Separator orientation="vertical" className="mx-1 h-6" />
                    </TableCell>
                    <TableCell
                      className={`tw-px-2 ${
                        editableField === field?.id ? "tw-bg-muted/100" : ""
                      } tw-w-[30%] tw-text-[12px]`}
                      onClick={() => toggleEditable(field.id)}
                    >
                      {" "}
                      {editableField === field?.id || field?.alias === "dnc" ? (
                        <div onClick={(e) => e.stopPropagation()}>
                          <FieldsMailbox
                            field={field}
                            control={control}
                            isEditable={editableField === field.id}
                            addRemoveDNC={addRemoveDNC}
                            dnc={dnc}
                          />
                        </div>
                      ) : (
                        <span className="tw-text-muted-foreground">
                          {formatFieldValue(field, watch(field.alias === "dnc" ? "doNotContact" : field.alias))}{" "}
                        </span>
                      )}
                    </TableCell>
                    {fieldValue ? (
                      <TableCell
                        className="font-medium tw-text-center hover:tw-scale-120 tw-duration-150 tw-cursor-pointer tw-w-[5%]"
                        onClick={(e) => {
                          e.stopPropagation();
                          setField(field.alias);
                        }}
                      >
                        {" "}
                        <ArrowBackIcon
                          sx={{
                            color: "hsl(215.4, 16.3%, 46.9%)",
                            fontSize: "14px",
                          }}
                          className="hover:tw-scale-110 tw-duration-200 tw-z-20"
                        />
                      </TableCell>
                    ) : (
                      <TableCell className="font-medium tw-text-center hover:tw-scale-120 tw-duration-150 tw-cursor-pointer tw-w-[5%]">
                        {" "}
                      </TableCell>
                    )}
                    <TableCell className="text-right tw-text-[12px] tw-w-[30%]">{getFieldValue(field.alias)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </ResizablePanel>
  );
};

export default FieldSection;
